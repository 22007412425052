/* istanbul ignore file */

import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ROUTES from '../../shared/constants/Routes';
import DocumentTypeSelect from '../../shared/components/select/document-type-select/DocumentTypeSelect';
import EventButton from '../../shared/components/button/event-button/EventButton';
import CitiesSelect from '../../shared/components/select/cities-select/CitiesSelect';

import group6 from '../../images/Group-6.svg';

import { findInspectionById } from '../../services/Inspections.service';
import { scheduleInspection } from '../../services/ScheduleInspection.service';
import { maskEmail, maskPhone, maskId } from '../../shared/helpers/masking.helper';

import './ScheduledHomeInspection.scss';
import ScheduledHomeInspectionDesktop from './scheduled-home-inspection-desktop/ScheduledHomeInspectionDesktop';
import DropArrow from '../../shared/components/drop-arrow/DropArrow';

import { analyticsService } from '../../services/analyticsService/analytics.service';

const ScheduledHomeInspection = () => {
  const { id } = useParams();
  const [inspectionId, setInspectionId] = useState(id);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [email, setEmail] = useState('');
  const [cityCode, setCityCode] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [address, setAddress] = useState('');
  const [brand, setBrand] = useState('');
  const [vehicleClass, setVehicleClass] = useState('');
  const [model, setModel] = useState('');
  const [plate, setPlate] = useState('');
  const [emailToShow, setEmailToShow] = useState('');
  const [idToShow, setIdToShow] = useState('');
  const [phoneToShow, setPhoneToShow] = useState('');

  const [redirect, setRedirect] = useState(undefined);
  const [error, setError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (inspectionId) {
      findInspectionById(inspectionId)
        .then(response => {
          if (response.status === 200) {
            const inspection = response.data;
            if (inspection.id) {
              setInspectionId(inspection.id);
            }

            if (inspection.firstName || inspection.lastName) {
              if (inspection.firstName && inspection.lastName) {
                setFullName(`${inspection.firstName} ${inspection.lastName}`);
              }
              if (inspection.firstName && !inspection.lastName) {
                setFullName(inspection.firstName);
              }
              if (!inspection.firstName && inspection.lastName) {
                setFullName(inspection.lastName);
              }
            }

            if (inspection.phone) {
              setPhoneNumber(inspection.phone);
              const maskedPhone = maskPhone(inspection.phone);
              setPhoneToShow(maskedPhone);
            }

            if (inspection.documentType) {
              setDocumentType(inspection.documentType);
            }

            if (inspection.documentNumber) {
              setDocumentNumber(inspection.documentNumber);
              const maskedId = maskId(`${inspection.documentNumber}`);
              setIdToShow(maskedId);
            }

            if (inspection.email) {
              setEmail(inspection.email);
              const maskedEmail = maskEmail(inspection.email);
              setEmailToShow(maskedEmail);
            }

            if (inspection.cityCode) {
              setCityCode(inspection.cityCode);
            }

            if (inspection.address) {
              setAddress(inspection.address);
            }

            if (inspection.brand) {
              setBrand(inspection.brand);
            }

            if (inspection.class) {
              setVehicleClass(inspection.class);
            }

            if (inspection.model) {
              setModel(inspection.model);
            }

            if (inspection.plate) {
              setPlate(inspection.plate);
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            return setRedirect(ROUTES.NOT_FOUND);
          }
        });
    }
  }, [inspectionId]);

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-domicilio-index - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const createOrderInspections = inspectionId => {
    scheduleInspection(inspectionId)
      .then(res => {
        return setRedirect(ROUTES.INSPECTIONS.HOME.CONFIRM);
      })
      .catch(() => {
        return setRedirect(ROUTES.INSPECTIONS.ERRORS.ERROR_SERVICE);
      });
  };

  const onSubmit = event => {
    event.preventDefault();
    if (
      fullName.value === '' ||
      phoneNumber.value === '' ||
      documentType.value === '' ||
      documentNumber.value === '' ||
      email.value === '' ||
      cityCode.value === '' ||
      address.value === ''
    ) {
      setError(true);
      return;
    }

    setError(false);

    createOrderInspections(inspectionId);

    const dataActionAnalyticsContinue = {
      eventProps: {
        category: 'emision',
        action: 'inspeccion a domicilio - index',
        label: 'continuar',
        type_flow: 'lineal'
      }
    };
    analyticsService('event', dataActionAnalyticsContinue);
  };

  return (
    <>
      <Helmet>
        <title>Inspección a domicilio - Liberty Seguros</title>
      </Helmet>
      <ScheduledHomeInspectionDesktop>
        <div className="drop-arrow-scheduled-home-inspection">
          <DropArrow brand={brand} vehicleClass={vehicleClass} model={model} plate={plate} />
        </div>
        <div className="container schedule-inspection-component">
          <div className="row margin-container">
            <h1 className="title-inspection">
              Solicita tu inspección <br /> <strong>a domicilio</strong>
            </h1>
            <form onSubmit={onSubmit}>
              {error ? 'Todos los campos son requeridos' : null}
              <div className="wrapper-form-inspection-home">
                <div className="personal-info-title">
                  <img src={group6} alt="Not Found" />
                  <h1>
                    Datos <strong>Personales</strong>
                  </h1>
                </div>
                <input type="hidden" name="inspectionId" value={inspectionId} />
                <div className="row-form-fullname">
                  <div className="six columns">
                    <input
                      type="text"
                      className="fullName u-full-width"
                      name="fullName"
                      placeholder="Nombre completo"
                      value={fullName}
                      onChange={event => setFullName(event.target.value)}
                    />
                  </div>
                  <div className="six columns">
                    <input
                      type="text"
                      className="phoneNumber u-full-width"
                      name="phoneNumber"
                      placeholder="Celular"
                      value={phoneToShow}
                      onChange={event => setPhoneToShow(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row-form-document-type">
                  <div className="six columns">
                    <DocumentTypeSelect
                      className="documentType u-full-width"
                      name="documentType"
                      value={documentType}
                      onChange={event => setDocumentType(event.target.value)}
                    />
                  </div>
                  <div className="six columns">
                    <input
                      type="text"
                      className="documentNumber u-full-width"
                      name="documentNumber"
                      placeholder="Número de documento"
                      value={idToShow}
                      onChange={event => setDocumentNumber(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row-form-email">
                  <div className="six columns">
                    <input
                      type="email"
                      className="email u-full-width"
                      name="email"
                      placeholder="Correo electrónico"
                      value={emailToShow}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="six columns">
                    <input
                      type="text"
                      className="address u-full-width"
                      name="address"
                      error={error}
                      placeholder="Dirección"
                      value={address}
                      onChange={event => setAddress(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row-form-cities-select">
                  <div className="six columns">
                    <CitiesSelect
                      error={error}
                      className="cities u-full-width"
                      placeholder="Ciudad donde realizará la inspección"
                      value={cityCode}
                      onChange={city => {
                        setCityCode(city.cityCode);
                        setStateCode(city.stateCode);
                      }}
                    />
                  </div>
                </div>
                <input type="hidden" name="cityCode" value={cityCode} />
                <input type="hidden" name="stateCode" value={stateCode} />
              </div>
              <hr className="line-divided-inspection-home" />
              <div className="button-date-info">
                <EventButton textButton="Continuar" type="submit" />
              </div>
            </form>
          </div>
        </div>
      </ScheduledHomeInspectionDesktop>
    </>
  );
};

export default ScheduledHomeInspection;
