import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import './ScheduledHomeInspectionDesktop.scss';

import { findInspectionById } from '../../../services/Inspections.service';

import repairAuto from '../../../images/errors/repair-auto.svg';
import Exclamation from '../../../images/inspection-desktop/inspection-home-desktop/Exclamation.svg';

const ScheduledHomeInspectionDesktop = props => {
  const { id } = useParams();
  const [inspectionId, setInspectionId] = useState(id);
  const [brand, setBrand] = useState('');
  const [vehicleClass, setVehicleClass] = useState('');
  const [model, setModel] = useState('');
  const [plate, setPlate] = useState('');

  /* istanbul ignore next */
  useEffect(() => {
    if (inspectionId) {
      findInspectionById(inspectionId)
        .then(response => {
          const inspection = response.data;

          if (inspection.id) {
            setInspectionId(inspection.id);
          }

          if (inspection.brand) {
            setBrand(inspection.brand);
          }

          if (inspection.class) {
            setVehicleClass(inspection.class);
          }

          if (inspection.model) {
            setModel(inspection.model);
          }

          if (inspection.plate) {
            setPlate(inspection.plate);
          }
        })
        .catch(error => {
          return;
        });
    }
  }, []);

  return (
    <>
      <div className="container-schedule-insp-home-desktop">
        <div className="wrapper">
          <div className="image-background-desktop" />
          <div className="card-inspection-home">
            <h1 className="title-card-inspection-home">Agenda tu inspección a domicilio</h1>
            <h3 className="subtitle-card-inspection-home">Por favor completa la siguiente información:</h3>
            <div className="table-description-vehicle">
              <table className="table-info-vehicle">
                <tr>
                  <td>
                    <img src={repairAuto} alt="No Found" />
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Marca</h3>
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Linea</h3>
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Modelo</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3 className="info-plate"> {plate} </h3>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {brand} </p>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {vehicleClass} </p>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {model} </p>
                  </td>
                </tr>
              </table>
            </div>
            <div className="note-info-container">
              <img className="exclamation-image-note" src={Exclamation} alt="No Found" />
              <p>
                Si los datos de tu vehículo no corresponden a la información que se muestra arriba, dirígite a un Centro
                de Inspeción Autorizado.
              </p>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduledHomeInspectionDesktop;
