import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './ConfirmInspection.scss';

import ConfirmInspectionDesktop from './confirm-inspection-desktop/ConfirmInspectionDesktop';
import EventButton from '../../shared/components/button/event-button/EventButton';

import workTableContact from '../../images/contacto_Mesa-de-trabajo-1.svg';
import group12 from '../../images/Group-12.svg';

import { analyticsService } from '../../services/analyticsService/analytics.service';

const ConfirmInspection = () => {
  const history = useHistory();

  const dataActionAnalyticsContinue = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion a domicilio - resumen',
      label: 'finalizar',
      type_flow: 'lineal'
    }
  };

  const handleClick = async() => {
    await analyticsService('event', dataActionAnalyticsContinue);
    window.location.href = 'https://www.libertycolombia.com.co/';
  };

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-domicilio-resumen - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  return (
    <>
      <Helmet>
        <title>Inspección a domicilio - Liberty Seguros</title>
      </Helmet>
      <ConfirmInspectionDesktop>
        <div className="container">
          <div className="row">
            <div className="title-inspection-confirm">
              <img src={group12} alt="Not Found" />
              <h1>
                Inspección <strong>a Domicilio</strong>
              </h1>
            </div>
            <div>
              <p className="info-visit-agend-p">
                Un asesor de servicio te contactará para ayudarte a agendar tu inspección, este servicio está
                <strong> sujeto a cobertura</strong>
              </p>
            </div>
            <div className="advisor-continer">
              <img className="image-confirm-inspection" src={workTableContact} alt="Not Found" />
            </div>
            <div>
              <p className="info-confirm">
                Cuando tengamos la respuesta de tu inspección, te contactaremos por correo o por medio de una llamada,
                para que continues con tu proceso de compra
              </p>
            </div>
            <div className="horizontal-line" />
            <div className="button-confirm-agend" onClick={handleClick}>
              <EventButton textButton="Finalizar" />
            </div>
          </div>
        </div>
      </ConfirmInspectionDesktop>
    </>
  );
};

export default ConfirmInspection;
