import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './InspectionScheduledConfirm.scss';

import EventButton from '../../../shared/components/button/event-button/EventButton';

import tallerImageConfirm from '../../../images/taller.svg';
import group12 from '../../../images/Group-3.svg';
import InspectionScheduledConfirmDesktop from './inspection-scheduled-confirm-desktop/InspectionScheduledConfirmDesktop';

import { useHistory } from 'react-router-dom';
import { analyticsService } from '../../../services/analyticsService/analytics.service';

const InspectionScheduleConfirm = () => {
  const history = useHistory();

  const dataActionAnalyticsCallExpert = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion cda - resumen',
      label: 'finalizar',
      type_flow: 'lineal'
    }
  };

  const handlerClick = async() => {
    await analyticsService('event', dataActionAnalyticsCallExpert);
    window.location.href = 'https://www.libertycolombia.com.co/';
  };

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-centro-de-diagnostico-resumen - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  return (
    <>
      <Helmet>
        <title>Inspección en centros de diagnóstico - Liberty Seguros</title>
      </Helmet>
      <InspectionScheduledConfirmDesktop>
        <div className="container-inspection-scheduled-confirm">
          <div className="row">
            <div className="title-inspection-scheduled-confirm">
              <img src={group12} alt="Not Found" />
              <h1>
                <strong>Centros de Diagnóstico</strong>
                <br />
                Automotor CDA
              </h1>
            </div>
            <div>
              <p className="thanks-inspection-shceduled-confirm">
                Te enviamos a tu correo los centros de diagnóstico autorizados de acuerdo a la ciudad que seleccionaste.
              </p>
            </div>
            <div>
              <img className="image-confirm-inspection-scheduled" src={tallerImageConfirm} alt="Not Found" />
            </div>
            <div className="send-info-inspection-scheduled-confirm">
              <p className="info-confirm-inspection-scheduled-confirm">
                Cuando tengamos la respuesta de tu inspección, te contactaremos por correo o por medio de una llamada,
                para que contiues con tu proceso de compra.
              </p>
            </div>
            <div className="horizontal-line-inspection-scheduled-confirm" />
            <div className="button-confirm-agend-inspection-scheduled-confirm" onClick={handlerClick}>
              <EventButton textButton="Finalizar" />
            </div>
          </div>
        </div>
      </InspectionScheduledConfirmDesktop>
    </>
  );
};

export default InspectionScheduleConfirm;
